import { SEO, UkBanner } from '@strike-apps/commerce-dashboard/ui'
import {
  Button,
  HStack,
  StrikeWordmarkHeader,
  Footer,
  Flex,
  Box,
  VStack,
  Text,
  Icon,
  Link,
} from '@strike-apps/shared/ui'
import { signIn } from 'next-auth/react'
import { CircleCheckIcon } from '@strike-apps/shared/icons'
import businessHero from './businessHero.svg'
import Image from 'next/image'
import NextLink from 'next/link'
import { getServerSidePropsWithRedirectToHomeIfLoggedIn } from '../../lib/utils/getServerSidePropsWithRedirectToHomeIfLoggedIn'

interface LoginProps {
  ipCountry: string // this is injected in _app.tsx
}

export function Login({ ipCountry }: LoginProps) {
  const features = [
    'Buy, sell, hold, or transfer your bitcoin',
    'Transact on-chain or via the Lightning Network',
    'Enjoy high limits and low fees',
  ]
  const isUK = ipCountry === 'gb'
  const strikeUkBusinessLink = (
    <Link href="https://strike.me/business" isExternal>
      Strike Business
    </Link>
  )

  return (
    <>
      <SEO />
      <Flex m="auto" minH="100vh" maxW="1440px" direction="column" justifyContent="space-between">
        <StrikeWordmarkHeader />
        {isUK && (
          <Box px={{ base: 4, sm: 12 }} py={2}>
            <UkBanner />
          </Box>
        )}
        <HStack gap={6} flex={1} alignItems="stretch" px={{ base: 4, sm: 12 }}>
          <VStack
            borderWidth="1px"
            borderColor="borderPrimary"
            borderRadius={12}
            alignItems="flex-start"
            justifyContent="center"
            spacing={0}
            px={6}
            flexBasis={{ base: '100%', md: '50%' }}
          >
            <Text variant="title2" color="facePrimary" mb={6}>
              Make your move into bitcoin
            </Text>
            <VStack color="facePrimary" gap={3} alignItems="flex-start" mb={12}>
              {features.map((text) => (
                <Flex key={text} gap={2} alignItems="center">
                  <Icon as={CircleCheckIcon} boxSize="20px" color="facePrimary" />
                  <Text variant="body2">{text}</Text>
                </Flex>
              ))}
            </VStack>
            <Flex
              gap={2}
              alignItems="flex-start"
              justifyContent="center"
              direction={{ base: 'column', sm: 'row' }}
              mb={6}
            >
              <Button onClick={() => signIn('strike')} size="md" colorScheme="primary">
                Log in
              </Button>
              <Link as={NextLink} href="/signup">
                <Button size="md" variant="outline" colorScheme="primary">
                  Sign up
                </Button>
              </Link>
            </Flex>
            {isUK && (
              <Box>
                <Text variant="caption" color="faceTertiary">
                  Learn more about {strikeUkBusinessLink}
                </Text>
                <Text variant="caption" color="faceTertiary">
                  This financial promotion was approved by Englebert Ltd on 29/10/2024.
                </Text>
              </Box>
            )}
          </VStack>
          <Box
            display={{ base: 'none', md: 'flex' }}
            borderWidth="1px"
            borderColor="borderPrimary"
            borderRadius={12}
            overflow="hidden"
            flexBasis="50%"
          >
            <Image
              src={businessHero}
              alt="business hero"
              width={879}
              height={879}
              style={{ objectFit: 'cover' }}
            />
          </Box>
        </HStack>
        <Footer />
      </Flex>
    </>
  )
}

export const getServerSideProps = getServerSidePropsWithRedirectToHomeIfLoggedIn

export default Login
